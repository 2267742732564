
import "../assets/layout.css"
import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"


import LeadForm from "./lead_form"

import 'font-awesome/css/font-awesome.min.css'
import { StaticImage, GatsbyImage , getImage} from "gatsby-plugin-image"

const Header = ({ siteTitle }) => {


  const toogleNav = () => {

    var site_nav =  document.getElementById("site_nav");
    var hamburger = document.getElementById("hamburger");
    hamburger.classList.toggle("active")
    site_nav.classList.toggle("active")
    
    //navMenu.classList.toggle("active")
}


return (



<header>

<LeadForm/>

 <div className ="container">
 <div className = "navigation">
   <div className = "logo">
   <Link to ="/"><StaticImage
      src="../images/top-logo.png"
      quality = "100"
      width="150"
      alt="Estore Company"
      placeholder = "tracedSVG"
    
     
     
    /></Link>
  </div>

  <div className ="hamburger" onClick = {toogleNav} id="hamburger">
                <span class="bar"></span>
                <span class="bar"></span>
                <span class="bar"></span>
            </div>



    
 <div className = "site_nav" id="site_nav">

 <ul>
      <li ><Link to ="/"><span>Home</span></Link></li>
      <li><Link to="/about-us"><span>About us</span></Link></li>
      <li><Link to="/virtual-assistants"><span>Hire VA</span></Link></li>
      <li><Link to="/services"><span>services</span></Link></li>
      <li><Link to="/case-study"><span>case study</span></Link></li>
      <li><Link to="/contact-us"><span>Contact us</span></Link></li>
      <li>
        
      <Link to="https://appweb.estorecompany.com/website-discovery-call-7557"><span>schedule a call</span></Link>
        
        </li>
      
</ul>



 </div>





 </div>
 
 </div>
     
  
  </header>
)
  

}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}



export default Header
