import * as React from "react"
import { Link } from "gatsby"


const LeadForm = (props) => {
 
    const  toggleModal = (e) => {
        e.preventDefault();
        document.querySelector(".modal").classList.toggle("show-modal");
    }



    const handleSubmit = (e) => {
        let myForm = document.getElementById('lead_form');
    let formData = new FormData(myForm)
  
          fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams( formData).toString()
        })
          .then(() =>{
  
            document.getElementById("response_msg").innerHTML = 
            `<div class="alert alert-success" role="alert">
            Your query has received; one of our account managers will be in touch with you shortly. <h3>Thank you!</h3></div>`;
           // alert("Success!");
            document.getElementById("lead_form").reset();

            setTimeout(function () {
                document.getElementById('response_msg').innerHTML = "" ;
            }, 8000);
  
          })
          .catch(error => alert(error));
  
        e.preventDefault();
  
          
      }
  


    return (
        <section>
              <Link to = "#" className = "trigger"  onClick = {toggleModal}   dangerouslySetInnerHTML={{ __html:props.name }}>
            
      

              </Link>
      
        <div className ="modal">
            <div className="modal-content">
                <h2>Let's Talk</h2>
                <span className="close-button" onClick = {toggleModal}>×</span>
               
                <form className="lead_form_form_cntrl" onSubmit = {handleSubmit} id ="lead_form" name="lead_form" method="post" data-netlify="true" data-netlify-honeypot="bot-field">

<input type="hidden" name="form-name" value="lead_form" />



<div className = "fields"> <input type="text" name="name"  placeholder = "Name" required/></div>
<div className = "fields"> <input type="email" name="email" placeholder = "Email" required /></div>
<div className = "fields"> <input type="tel" name="phone" placeholder = "Phone"   required   /></div>



<textarea placeholder = "Project Details" name = "tell_us"></textarea>


<button type = "submit">SEND</button>

<div id = "response_msg"></div>
</form>



            </div>
        </div>
        </section>
        
        )



}

export default LeadForm