import * as React from "react"

import { Link } from "gatsby"
import $ from 'jquery'
import { StaticImage, GatsbyImage , getImage} from "gatsby-plugin-image"
import CookieConsent from "react-cookie-consent";
import "../assets/custom.js"


const Footer = () => {



  
    return (
        
        <>
        <footer>
       
       <div className ="container">

<div className ="grid_foo">
        <div className ="footer_nav">

              <ul>
                  <h2>our company</h2>
                  <li><Link to ="/about-us" className ="a_btn">About us</Link></li>
                  <li><Link to ="/virtual-assistants" className ="a_btn">Hire VA</Link></li>
                  <li><Link to ="/services" className ="a_btn">Our Services</Link></li>
                  <li><Link to ="/career" className ="a_btn">Careers</Link></li>
                  <li><Link to ="/contact-us" className ="a_btn">Contact Us</Link></li>
                  <li><Link target = "_blank" to ="https://appweb.estorecompany.com/website-discovery-call-1104" className ="a_btn">Let's Talk</Link></li>
                  <li><Link className ="a_btn" to = "/blog">Blog</Link></li>
                  <li><Link className ="a_btn" to ="/privacy-policy">Privacy Policy</Link></li>
                  <li><Link className ="a_btn" to ="/accessibility-policy">Accessibility Policy</Link></li>
                  <li><Link className ="a_btn" to ="/terms-conditions">Terms & Conditions </Link></li>
              </ul>

              <ul>
                  <h2>General services</h2>
                  <li><Link to ="/ecommerce-service/shopify" className ="a_btn">Shopify Services</Link></li>
                  <li><Link to ="/ecommerce-service/woo-commerce" className ="a_btn">WooCommerce Services</Link></li>
                  <li><Link to ="/ecommerce-service/magento-2" className ="a_btn">Magento Services</Link></li>
                  <li><Link to ="/ecommerce-service/big-commerce" className ="a_btn">BigCommerce Services</Link></li>
                  <li><Link to ="/services" className ="a_btn">Digital Marketing </Link></li>
                  <li><Link to ="/services" className ="a_btn">Graphic Design </Link></li>
                  <li><Link to ="/services" className ="a_btn">Web Design & Development</Link></li>
              </ul>

              
              <ul>
                  <h2>VA Services</h2>
                  <li><Link to ="/creative-service" className ="a_btn">Creative Virtual Assistant</Link></li>
                  <li><Link to ="/ecommerce-service" className ="a_btn">Ecommerce Virtual Assistant </Link></li>
                  <li><Link to ="/hr-service" className ="a_btn">Recruiter Virtual Assistant</Link></li>
                  <li><Link to ="/development-service" className ="a_btn">Generalist  Virtual Assistant</Link></li>
                  <li><Link to ="/marketing-service" className ="a_btn">Marketing Virtual Assistant</Link></li>
                  <li><Link to ="/pm-service" className ="a_btn">Project Manager Virtual Assistant</Link></li>
                 
              </ul>

             



        </div>

        <div className = "co_branding">

        <StaticImage
      src="../images/top-logo.png"
      width={150}
      alt="A Gatsby astronaut"
      placeholder="blurred"
     
    />

    <ul>
        <li>
          <Link to ="https://www.facebook.com/estorecompanyusa" target  = "_blank"><i className="fa fa-facebook" aria-hidden="true"></i></Link>
        </li>
        <li>
          <Link to ="https://www.instagram.com/estorecompany/?hl=en" target  = "_blank"><i className="fa fa-instagram" aria-hidden="true"></i></Link>
        </li>

        <li>
          <Link to ="https://www.linkedin.com/company/estorecompany/" target  = "_blank"><i className="fa fa-linkedin" aria-hidden="true"></i></Link>
        </li>

        <li>
          <Link to ="https://twitter.com/companyestore" target  = "_blank"><i className="fa fa-twitter" aria-hidden="true"></i></Link>
        </li>

        <li>
          <Link to ="https://www.youtube.com/channel/UCmFKtuKREnw0GVdIPgSuEBA/featured" target  = "_blank"><i className="fa fa-youtube-play" aria-hidden="true"></i></Link>
        </li>


      </ul>


      
        </div>

       </div>


      <div className ="shot_foo">

        <ul className ="countries">
          <li>united states</li>
          <li>pakistan</li>
          <li>united arab emirated</li>
          <li>ireland</li>

        </ul>

        <ul className ="copyright">
          <li>Copyright © {new Date().getFullYear()}</li>
          <li>Estore Company Private Limited</li>
          <li>All rights reserved</li>
        

        </ul>


      </div>

       </div>
       
       <CookieConsent
       
  location="bottom"
  style={{ background: "#000", alignItems: "center"}}
  buttonStyle={{ padding : "10px 10px" ,color: "#000", fontSize: "12px", background : "#f5d315",borderRadius : "2px", fontFamily : "'Objectivity Bold'" }}
  buttonText="Accept!!!"
  cookieName="gdpr"
  buttonClasses="btn btn-primary"
  containerClasses="gdpr-container"
  

  expires={150}
>
This website stores cookies on your computer. These cookies are used to improve your website and provide more
 personalized services to you, both on this website and through other media. To find out more
 about the cookies we use, see our <Link to = "" style = {{color: "#f5d315"}}>Privacy Policy</Link> 
</CookieConsent>
      
        
      </footer>
    <chat-widget
    style = {{'--chat-widget-primary-color':'#f5d315', '--chat-widget-active-color':'#f5d315' , '--chat-widget-bubble-color': '#f5d315'}}
          
            location-id="Eqo9O9eDschT11hvE78D"
            revisit-prompt-msg="Welcome back {{name}}, how can we help you today?"
            prompt-avatar="https://widgets.leadconnectorhq.com/chat-widget/assets/defaultAvatar.png"
            
      ></chat-widget>
       <script src="https://widgets.leadconnectorhq.com/loader.js"
          data-resources-url="https://widgets.leadconnectorhq.com/chat-widget/loader.js" ></script>
          
</>
    )





}

export default Footer;