/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */


 import Header from "./header"

import React, {useEffect} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, withPrefix } from "gatsby"
import { Helmet } from "react-helmet"


import Footer from "./footer"


import customJS from "../assets/custom.js";


const Layout = ({ children }) => {

  useEffect(()=>{
    customJS()
    
  }, [])

  
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
     
        <main>{children}</main>
    
      <Footer/>

 
   
    
    </>
   
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
