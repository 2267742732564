import jQuery from 'jquery'



  const customJS =()=> {

    jQuery(function(){ 
        jQuery('#toggle').click(function() {
            jQuery(this).toggleClass('active');
            jQuery('#overlay').toggleClass('open');
           });

           const tabLinks = jQuery('#tab-links li a');
	
	// Handle link clicks.
	tabLinks.click(function(event) {
		var current = jQuery(this);
		
		// Prevent default click behaviour.
		event.preventDefault();
		
		// Remove the active class from the active link and section.
		jQuery('#tab-links a.active, section.active').removeClass('active');
		
		// Add the active class to the current link and corresponding section.
		current.addClass('active');
		jQuery(current.attr('href')).addClass('active');
	});
           console.log('jquery');


           jQuery(".set > a").on("click", function(e) {
             e.preventDefault();
            if (jQuery(this).hasClass("active")) {
              jQuery(this).removeClass("active");
              jQuery(this)
                .siblings(".content")
                .slideUp(200);
                jQuery(".set > a i")
                .removeClass("fa-minus")
                .addClass("fa-plus");
            } else {
              jQuery(".set > a i")
                .removeClass("fa-minus")
                .addClass("fa-plus");
                jQuery(this)
                .find("i")
                .removeClass("fa-plus")
                .addClass("fa-minus");
                jQuery(".set > a").removeClass("active");
                jQuery(this).addClass("active");
                jQuery(".content").slideUp(200);
                jQuery(this)
                .siblings(".content")
                .slideDown(200);
            }
          });


          jQuery(document).on("click", ".naccs .menu div", function() {
            var numberIndex = jQuery(this).index();
          
            if (!jQuery(this).is("active")) {
              jQuery(".naccs .menu div").removeClass("active");
              jQuery(".naccs ul li").removeClass("active");
          
              jQuery(this).addClass("active");
              jQuery(".naccs ul").find("li:eq(" + numberIndex + ")").addClass("active");
          
              
            }
          });
           
          
      })
  
     

      

  }

  

  export default customJS;  